<template>
<section class="min-h-screen w-full flex flex-col justify-center items-center">
    <h1 class="text-5xl font-bold">Error 404</h1>
    <p>
        Back to
        <router-link to="/" class="text-red-700 hover:text-blue-900">Home Page</router-link>
    </p>
</section>
</template>

<script>
export default {
    name: "Error",
};
</script>
